import type { Question } from '../forms/forms.types';

type DamageGuides = Record<string, DamageGuideSection>;

type DamageGuideSection = {
  header: string;
  subHeader: string;
  subSections: DamageGuideSubSection[];
};

type DamageGuideSubSection = {
  header: string;
  subHeader: string;
  images?: string[];
};

export type QuestionSet = Readonly<{
  autoTag?: boolean;
  count: number;
  formName: string;
  guid: string;
  damageGuides?: DamageGuides;
  items: Array<Question>;
  asIs?: boolean;
  metaData?: Record<string, unknown>;
}>;

export type UpdateFormData = {
  guid: string;
  questionSet?: QuestionSet | QuestionSetParams;
  env?: string;
  isTest?: boolean;
  isLatest?: boolean;
};

export type DeployFormDataType = {
  env: ApplicationEnvironments;
  guid: string;
  isTest: boolean;
  isLatest: boolean;
  user: string;
};

export type DeployModalDataType = {
  formId: string;
  deployEnvironment: string | undefined;
  selectedForm?: string;
  formName?: string;
  selectedVersion?: string;
  isLatest?: boolean;
  isTest?: boolean;
  user: string;
};

export enum ApplicationEnvironments {
  int1 = 'int1',
  int5 = 'int5',
  uat = 'uat',
  prod = 'prod',
}

export type QuestionSetParams = Omit<QuestionSet, 'count' | 'formName' | 'guid'>;

export type UpdateFormDataNoGuid = Partial<UpdateFormData>;
